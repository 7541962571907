import React, { useEffect } from 'react'
import ProjectTemplate from '../../components/template/ProjectTemplate';

const ImageClassificationResNet152V2 = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const content = [
        { type: 'heading', 
            text: 'Introduction' 

        },
        { type: 'text', 
            text: (
                <>
                Proyek ini bertujuan untuk mengklasifikasikan gambar hewan ke dalam 10 kategori yang berbeda 
                menggunakan model Convolutional Neural Network (CNN). Dataset yang digunakan terdiri dari 26.179 gambar dengan kategori 
                dengan 10 kategori. <br /><br />
                Model yang digunakan adalah <strong>ResNet152V2</strong> sebagai base model yang diikuti oleh beberapa layer tambahan,
                termasuk <strong>Self-Attention</strong> custom layer, untuk meningkatkan performa klasifikasi. <br /><br />

                Pada akhir proyek ini, model akan dikonversi ke tiga model yang berbeda yaitu SavedModel, TF-Lite, dan TensorflowJS (TFJS).
                </>
            )

        },

        { type: 'heading', 
            text: 'What is ResNet152V2?' 

        },

        { type: 'text', 
            text: (
                <>
                Resnet merupakan keluarga dari beberapa model deep neural networks yang dirancang untuk mengatasi masalah 
                degradasi kinerja pada model deep learning yang sangat dalam. Pada jaringan yang lebih dalam, penambahan 
                lapisan justru bisa menyebabkan kinerja menurun atau menjadi lebih sulit dilatih, bahkan ketika tidak ada overfitting.
                <br /><br />
                Untuk mengatasi masalah ini, ResNet memperkenalkan residual bloks, yang menggunakan skip connections. 
                Pada setiap blok residual, sinyal asli dilewatkan langsung ke lapisan lebih lanjut tanpa perubahan, 
                sehingga memungkinkan model untuk belajar perbedaan (residual) antara input dan output daripada belajar 
                fitur baru dari nol.
                <br /><br />
                Adapun perbedaan Resnet V2 dengan ResNet V1 terletak pada penempatan normalisasi batch dan ReLu 
                activation function di dalam residual block.
                </>
            )
        },
        {type: 'list',
            items: [
                'ResNet V1: Batch normalization dan ReLu diletakan setelah konvolusi (post-activation).',
                'ResNet V2: Batch normalization dan ReLu diletakan setelah konvolusi (pre-activation), yang diklaim lebih stabil saat pelatihan dan meningkatkan kinerja dalam jaringan yang sangat dalam.'
            ]
        },

        { type: 'heading', 
            text: 'Dataset' 

        },

        {type: 'text',
            text: (
                <>
                Dataset yang digunakan pada proyek ini adalah{' '}<a href="https://www.kaggle.com/datasets/alessiocorrado99/animals10" target="_blank" rel="noopener noreferrer" className='custom-link'>
                    Animals-10
                </a>{' '}dari kaggle. Dataset ini berisi 10 hewan, contoh gambar dan distribusinya dapat dilihat pada gambar-gambar berikut:
                </>
            )
        },
        
        { type: 'image', src: '/assets/image_classification/example_image.webp', alt: 'Project 1 Image 1' },
        { type: 'image', src: '/assets/image_classification/train_class_distribution.webp', alt: 'Project 1 Image 2' },
        { type: 'image', src: '/assets/image_classification/val_class_distribution.webp', alt: 'Project 1 Image 3' },

        {type: 'text',
            text: (
                <>
                Perhatikan distribusi kelas data training, distribusi tersebut bisa dikatakn imbalance. Karena, terlihat kelas 'dog' dan 'ragno' memiliki jumlah
                gambar yang lebih tinggi, yaitu hampir 4000 gambar. Kesenjangan jumlah antar kelas dapat mempengaruhi kinerja model, serta dapat membuat model
                menjadi terlalu bias terhadap kelas yang lebih besar. <br /> <br/>

                Imbalance juga terjadi pada data validasi. Hal ini juga dapat mempengaruhi kinera model, terutama pada metrik evaluasi yang tidak akurat.
                Metrik seperti <strong>accuracy</strong> bisa menyesatkan dalam situasi ketidakseimbangan kelas. Misalnya, jika sebagian besar sampel adalah dari 
                kelas mayoritas, model dapat dengan mudah memprediksi kelas tersebut secara konsisten dan mendapatkan nilai accuracy tinggi meskipun model 
                tidak performa baik untuk kelas minoritas. <br/><br/>

                Untuk mengatasi masalah imbalance pada dataset, teknik data augmentation digunakan untuk data training, dan menggunakan metrik F1-Score, 
                precission, dan recall yang lebih cocok untuk evaluasi ketidakseimbangan dataset khususnya data validasi.
                </>
            )
        },

        {type: 'heading', text: 'Data Preprocessing'},
        {type: 'sub-heading', text: '1. Pengecekan Gambar dengan Format PNG'},
        {type: 'text', 
            text: (
                <>
                Gambar dalam format PNG terkadang menyebabkan masalah terkait profil warna sRGB yang tidak sesuai standar. Walaupun pesan peringatan "iCCP: known 
                incorrect sRGB profile" biasanya tidak mempengaruhi performa model, pesan tersebut bisa mengganggu keterbacaan selama pelatihan. <br/>
                Kode berikut digunakan untuk mencari gambar dalam format PNG di direktori dataset:
                </>
            )
        },
        { type: 'code', 
        code: `def check_png_files(directory):
        png_files = []
            for root, dirs, files in os.walk(directory):
                for file in files:
                    if file.lower().endswith('.png'):
                        png_files.append(os.path.join(root, file))
            return png_files` 
        },
        {type: 'text', 
            text: (
                <>
                Kode ini akan menghitung jumlah gambar dengan format PNG dan mencetak hasilnya:
                </>
            )
        },
        { type: 'code', 
        code: `def count_png(directory):
        png_files = check_png_files(directory)
        if png_files:
            print(f'Terdapat {len(png_files)} gambar PNG dalam dataset.')
        else:
        print('Tidak ada gambar PNG dalam dataset.')`
        },
        {type: 'sub-heading', text: '2. Konversi Gambar PNG ke JPEG'},
        {type: 'text',
            text: (
                <>
                Setelah menemukan gambar berformat PNG, langkah selanjutnya adalah mengonversi gambar 
                tersebut ke format JPEG untuk menghindari potensi masalah di masa depan. Proses konversi 
                dilakukan dengan mengubah setiap gambar PNG menjadi gambar RGB dan menyimpannya kembali dengan format .jpg:
                </>
            )
        },
        {type: 'code',
        code: `def convert_image_to_jpeg(file_path):
        try:
            with Image.open(file_path) as img:
                rgb_img = img.convert("RGB")
                jpeg_file_path = file_path.rsplit('.', 1)[0] + '.jpg'
                rgb_img.save(jpeg_file_path, "JPEG")
                print(f"Converted {file_path} to {jpeg_file_path}")
                return jpeg_file_path
        except Exception as e:
            print(f"Could not convert image {file_path}: {e}")
            return None`
        },
        {type: 'text', text: 'Setelah gambar dikonversi, file PNG asli akan dihapus:'},
        {type: 'code',
        code: `def convert(directory):
        for root, _, files in os.walk(directory):
            for filename in files:
                if filename.endswith('.png'):
                    file_path = os.path.join(root, filename)
                    convert_image_to_jpeg(file_path)
                    os.remove(file_path)`
        },
        {type: 'sub-heading', text: '3. Load Dataset'},
        {type: 'text', 
            text: (
                <>Dataset pelatihan dan validasi di-load menggunakan fungsi image_dataset_from_directory yang secara 
                otomatis menyusun dataset berdasarkan struktur direktori. Pada tahap ini, gambar diubah ukurannya menjadi 
                resolusi 224x224, dan batch size diatur menjadi 32:
                </>
            )
        },

        {type: 'code',
        code: 
        `train_ds = image_dataset_from_directory(
            train_dir,
            labels='inferred',
            label_mode='categorical',
            batch_size=32,
            image_size=(224, 224),
            seed=42
        )
val_ds = image_dataset_from_directory(
            val_dir,
            labels='inferred',
            label_mode='categorical',
            batch_size=32,
            image_size=(224, 224),
            seed=42
        )`
        },
        {type: 'sub-heading', text:'4. Data Augmentation'},
        {type: 'text', 
            text: (
                <>
                Untuk memperkaya variasi data selama pelatihan, beberapa teknik augmentasi 
                gambar diterapkan. Teknik augmentasi yang digunakan termasuk:
                </>
            )
        },
        {type: 'list',
            items: [
                'Normalisasi dengan membagi piksel gambar dengan 255 untuk mendapatkan nilai antara [0, 1].',
                'Pengaturan brightness untuk menambahkan variasi kecerahan pada gambar.',
                'Flip gambar horizontal untuk meningkatkan kemampuan model mengenali objek yang diputar.',
                'Penyesuaian kontras dan saturasi untuk memberikan variasi warna dan intensitas pada gambar.',
                'Rotasi acak untuk menambahkan variasi sudut pandang.'
            ]
        },
        {type: 'text', text: 'Augmentasi dilakukan dalam fungsi berikut:'},
        {type: 'code', 
        code: `def train_preprocess_and_augment(image, label):
        image = image / 255.0  # Scale images to [0, 1]
        image = tf.image.random_brightness(image, max_delta=0.2)
        image = tf.image.flip_left_right(image)
        image = tf.image.random_contrast(image, lower=0.8, upper=1.2)
        image = tf.image.random_saturation(image, lower=0.8, upper=1.2)
        image = tf.image.central_crop(image, central_fraction=0.9)
        
        image = rotation_layer(image)
        image = tf.image.resize(image, [224, 224])
        image = tf.clip_by_value(image, 0.0, 1.0)  # Pastikan nilai berada dalam [0, 1]
        
        return image, label`
        },
        {type: 'text', 
            text: (
                <>
                Untuk data validasi, augmentasi tidak diterapkan karena data validasi 
                digunakan untuk evaluasi, sehingga hanya normalisasi gambar yang dilakukan:
                </>
            )
        },
        {type: 'code',
        code: `def val_preprocess(image, label):
        image = image / 255.0  # Scale images to [0, 1]
        return image, label`
        },
        {type: 'sub-heading', text: '5. Pipeline Dataset dengan Prefetching dan Caching'},
        {type: 'text',
            text: (
                <>
                Dataset pelatihan dan validasi kemudian diproses dalam pipeline 'tf.data' untuk meningkatkan performa. 
                Teknik caching dan prefetching digunakan untuk mempercepat pengambilan data selama pelatihan dengan cara 
                memuat data di memori sebelum dibutuhkan:
                </>
            )
        },
        {type: 'code', 
        code: `train_ds = train_ds.cache().shuffle(1000).prefetch(buffer_size=AUTOTUNE)
val_ds = val_ds.cache().prefetch(buffer_size=AUTOTUNE)`
        },

        {type: 'heading', text: 'Modeling'},
        {type: 'text',
            text: (
                <>
                Modeling menggunakan pre-trained model ResNet152V2 dengan tambahan layer `SelfAttention`. Implementasi layer 
                Self-Attention dalam jaringan saraf konvolusi (CNN) bertujuan untuk meningkatkan representasi fitur 
                dengan memungkinkan model untuk fokus pada bagian penting dari input. Penjelasan lengkap mengenai integrasi 
                Self-Attention dengan CNN dapat dibaca pada paper yang berjudul: 
                <a href="https://arxiv.org/pdf/2111.14556" target="_blank" rel="noopener noreferrer" className='custom-link'>
                    On the Integration of Self-Attention and Convolution
                </a> <br/><br/>
                Potongan arsitektur model dapat dilihat pada gambar di bawah ini, untuk kode layer Self-Attention dapat dilihat{' '}
                <a href="https://github.com/Mnjar/resnet152v2-self-attention-classification" target="_blank" rel="noopener noreferrer" className='custom-link'>
                    di sini.
                </a></>
            )
        },
        { type: 'image', src: '/assets/image_classification/model.webp', alt: 'Project 1 Image 4' },
        
        {type: 'heading', text: 'Result and Evaluation'},
        {type: 'text', text: 'Hasil training model dapat dilihat pada gambar berikut:'},
        {type: 'large-image', src: '/assets/image_classification/plot_training.webp', alt: 'Project 1 Image 5'},
        {type: 'text',
            text: (
                <>
                Berdasarkan hasil di atas, kemampuan belajar pre-trained model ResNet152V2 dengan tambahan layer Selft-Attention memberikan 
                hasil yang baik dengan epoch kurang dari 10. Hal tersebut menandakan, model ini sangat powerfull untuk digunakan pada kasus klasifikasi gambar 
                dengan multi-label. Untuk hasil evaluasi lebih detail dapat dilihat pada classification report berikut:
                </>
            )
        },
        {type: 'image', src: '/assets/image_classification/classification_report.webp', alt: 'Project 1 Image 6'},

        {type: 'text',
            text: (
                <>
                Source code dapat dilihat{' '}
                <a href="https://github.com/Mnjar/resnet152v2-self-attention-classification" target="_blank" rel="noopener noreferrer" className='custom-link'>
                    di sini.
                </a>
                </>
            )
        },

        {type: 'text',
            text: (
                <>
                Terima kasih telah membaca dan berkunjung sampai akhir🙌.
                </>
            )
        },


    ];

    return (
        <ProjectTemplate
            title="Image Classification Using a Pre-trained ResNet152V2 Model with an Additional Self-Attention Layer"
            content={content}
        />
    );
}

export default ImageClassificationResNet152V2
import React from 'react';
import './project-template.css';

const ProjectTemplate = ({ title, content }) => {
    return (
        <div className="project-template">
            <article>
                <h1 className="project-title">{title}</h1>
                {content.map((item, index) => {
                    switch (item.type) {
                        case 'heading':
                            return <p key={index} className="project-heading">{item.text}</p>;
                        case 'sub-heading':
                            return <p key={index} className="project-sub-heading">{item.text}</p>;
                        case 'text':
                            return <p key={index} className="project-text">{item.text}</p>;
                        case 'image':
                            return <img key={index} src={item.src} alt={item.alt} className="project-image" />;
                        case 'large-image':
                            return <img key={index} src={item.src} alt={item.alt} className="large-project-image" />;
                        case 'list':
                            return (
                                <ul key={index} className="project-list">
                                    {item.items.map((listItem, idx) => (
                                        <li key={idx} className="project-list-item">{listItem}</li>
                                    ))}
                                </ul>
                            );
                        case 'code':
                            return (
                                <div key={index}>
                                    <p className="project-code-header">{item.header}</p>
                                    <pre className="project-code">{item.code}</pre>
                                </div>
                            );
                        default:
                            return null;
                    }
                })}
            </article>
        </div>
    );
};

export default ProjectTemplate;

import React from 'react'
import "./skills.css"
import MachineLearning from './MachineLearning'
import DeepLearning from './DeepLearning'
import DataScience from './DataScience'

const Skills = () => {
    return (
        <section className="skills section" id="skills">
            <h2 className="section__title">Skills</h2>
            <span className="section__subtitle">My technical skill</span>

            <div className="skills__container container grid">
                <MachineLearning/>
                <DeepLearning/>
                <DataScience/>
            </div>
        </section>
    )
}

export default Skills
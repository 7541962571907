import React from 'react'

const DeepLearning = () => {
  return (
    <div className="skills__content">
        <h3 className="skills__title">Deep Learning</h3>

        <div className="skills__box">
            <div className="skills__group">
                <div className="skills__data">
                <i className='bx bxs-badge-check' style={{ color: '#ffffff' }}></i>
                <div>
                    <h3 className="skills__name">
                        Neural Networks:
                    </h3>
                    <span className="sub__skills">
                        Feedforward Networks, Convolutional Neural Networks (CNNs), 
                        Recurrent Neural Networks (RNNs)
                    </span>
                </div>
                </div>

                <div className="skills__data">
                <i className='bx bxs-badge-check' style={{ color: '#ffffff' }}></i>
                <div>
                    <h3 className="skills__name">
                        Libraries/Frameworks:
                    </h3>
                    <span className="sub__skills">
                        Tensorflow, PyTorch, Keras
                    </span>
                </div>
                </div>

                <div className="skills__data">
                <i className='bx bxs-badge-check' style={{ color: '#ffffff' }}></i>
                <div>
                    <h3 className="skills__name">
                        Advanced Techniques:
                    </h3>
                    <span className="sub__skills">
                        Transfer Learning, GANs, LSTM
                    </span>
                </div>
                </div>
            </div>
            
        </div>
    </div>
  )
}

export default DeepLearning
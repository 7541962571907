import React from 'react'

const MachineLearning = () => {
    return (
        <div className="skills__content">
            <h3 className="skills__title">Machine Learning</h3>

            <div className="skills__box">
                <div className="skills__group">
                    <div className="skills__data">
                    <i className='bx bxs-badge-check' style={{ color: '#ffffff' }}></i>
                    <div>
                        <h3 className="skills__name">
                            Algorithms:
                        </h3>
                        <span className="sub__skills">
                            Supervised Learning (e.g., Linear Regression, Decision Trees), 
                            Unsupervised Learning (e.g., K-Means Clustering)</span>
                    </div>
                    </div>

                    <div className="skills__data">
                    <i className='bx bxs-badge-check' style={{ color: '#ffffff' }}></i>
                    <div>
                        <h3 className="skills__name">
                            Libraries/Frameworks:
                        </h3>
                        <span className="sub__skills">
                            Scikit-learn, XGBoost, LightGBM
                        </span>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MachineLearning
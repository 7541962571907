import React from "react";
import "./footer.css"

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer__container container">

                <div className="footer__social">
                    <a href="https://www.instagram.com/mnjar_" className="footer__social-link" target="_blank" rel="noreferrer">
                        <i className="uil uil-instagram"></i>
                    </a>
                    <a href="https://github.com/mnjar" className="footer__social-link" target="_blank" rel="noreferrer">
                        <i className="uil uil-github-alt"></i>
                    </a>
                    <a href="https://www.linkedin.com/in/muhamad-fajar-faturohman" className="footer__social-linkedin" target="_blank" rel="noreferrer">
                        <i className='bx bxl-linkedin-square'></i>
                    </a>
                </div>

                <span className="footer__copy">
                    &#169; Moki. All rights reserved
                </span>
            </div>
        </footer>
    )
};

export default Footer;

import React from "react";
import "./home.css"
import Social from "./Social";
import Data from "./Data";
import ScrollDown from "./ScrollDown";
import { Helmet } from "react-helmet";

<meta name="description" content="Welcome to my personal portfolio. Explore my skills, projects, 
    and professional journey in AI, Machine Learninge, and data science. Let's connect and create something amazing together."
    />

const Home = () => {
    return (
        <>
            <Helmet>
                <meta name="description" content="Welcome to my personal portfolio. Explore my skills, projects, and professional journey in AI, Machine Learning, and data science. Let's connect and create something amazing together." />
                <title>Home - My Portfolio</title>
            </Helmet>
        <section className="home section" id="home">
            <div className="home__container container grid">
                <div className="home__content grid">
                    <Social/>

                    <div className="home__img"></div>

                    <Data/>
                </div>

                <ScrollDown/>
            </div>
        </section>
        </>
    )
}

export default Home
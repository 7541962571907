import React from 'react'

const DataScience = () => {
  return (
    <div className="skills__content">
    <h3 className="skills__title">Data Science</h3>

    <div className="skills__box">
        <div className="skills__group">
            <div className="skills__data">
            <i className='bx bxs-badge-check' style={{ color: '#ffffff' }}></i>

            <div>
                <h3 className="skills__name">
                    Data Preprocessing:
                </h3>
                <span className="sub__skills">
                    Pandas, NumPy, Data Wrangling, Data Cleaning
                </span>
            </div>
            </div>

            <div className="skills__data">
            <i className='bx bxs-badge-check' style={{ color: '#ffffff' }}></i>

            <div>
                <h3 className="skills__name">
                    Data Visualization:
                </h3>
                <span className="sub__skills">
                    Matplotlib, Seaborn, Plotly
                </span>
            </div>
            </div>

            <div className="skills__data">
            <i className='bx bxs-badge-check' style={{ color: '#ffffff' }}></i>
            <div>
                <h3 className="skills__name">
                    Statistical Analysis:
                </h3>
                <span className="sub__skills">
                    Hypothesis Testing, ANOVA, Time Series Analysis
                </span>
            </div>
            </div>
        </div>
        
    </div>
</div>
  )
}

export default DataScience